/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import NavBar from "./navbar"


const Layout = ({ children }) => {
  return (
    <>
      <div
      >
        <Helmet>
          <script async="true" src="https://use.fortawesome.com/31252d2b.js"/>
        </Helmet>
        <NavBar/>
        <main style={{
          height: '100vh',
        }}
        >{children}
        </main>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
