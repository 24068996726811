import React from "react"

import { Tween, Timeline } from 'react-gsap';
import { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { isDayTime } from "../helpers"


const TimelineComponent = () => {
  const data = useStaticQuery(graphql`
      query {
        bg1: file(relativePath: { eq: "background1.png" }) {
          childImageSharp {
            fixed(width: 640, height: 640) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        bg2: file(relativePath: { eq: "background2.png" }) {
          childImageSharp {
            fixed(width: 640, height: 640) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        bg3: file(relativePath: { eq: "background3.png" }) {
          childImageSharp {
            fixed(width: 640, height: 640) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cloud4: file(relativePath: { eq: "cloud4.png" }) {
          childImageSharp {
            fixed(width: 161) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cloud2: file(relativePath: { eq: "cloud2.png" }) {
          childImageSharp {
            fixed(width: 147) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cloud7: file(relativePath: { eq: "cloud7.png" }) {
          childImageSharp {
            fixed(width: 181) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        bg1Night: file(relativePath: { eq: "background4-night.png" }) {
          childImageSharp {
            fixed(width: 640, height: 640) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        bg2Night: file(relativePath: { eq: "background2-night.png" }) {
          childImageSharp {
            fixed(width: 640, height: 640) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        bg3Night: file(relativePath: { eq: "background3-night.png" }) {
          childImageSharp {
            fixed(width: 640, height: 640) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cloud4Night: file(relativePath: { eq: "cloud4-night.png" }) {
          childImageSharp {
            fixed(width: 161) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cloud2Night: file(relativePath: { eq: "cloud2-night.png" }) {
          childImageSharp {
            fixed(width: 147) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cloud7Night: file(relativePath: { eq: "cloud7-night.png" }) {
          childImageSharp {
            fixed(width: 181) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `)
  let bg

  if (isDayTime) {
    bg =
      <div>
          {/* Background 1*/}
        <Timeline
          wrapper={
            <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -500, width: '100%', height: '100%' }}/>
          }
          target={
            <Fragment>
              <Img
                style={{ position: 'absolute', padding: 0, margin: 0, top: 0, left: 0, zIndex: -300, width: '100%', height: '100%', backgroundSize: 'cover' }}
                fixed={data.bg1.childImageSharp.fixed}
              />
            </Fragment>
          }
        />

        {/* Background 2*/}
        <Timeline
          wrapper={
            <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -500, width: '100%', height: '100%' }}/>
          }
          target={
            <Fragment>
              <Img
                style={{ position: 'absolute', padding: 0, margin: 0, top: 0, left: 0, zIndex: -300, width: '100%', height: '100%' }}
                fixed={data.bg2.childImageSharp.fixed}
              />
            </Fragment>
          }
        />

        {/* Background 3*/}
        <Timeline
          wrapper={
            <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -500, width: '100%', height: '100%' }}/>
          }
          target={
            <Fragment>
              <Img
                style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: '0%', zIndex: -200, width: '100%', height: '100%', backgroundSize: 'cover' }}
                fixed={data.bg3.childImageSharp.fixed}
              />
            </Fragment>
          }
          repeat={-1}
        >
        </Timeline>

        {/*Clouds*/}
        {/*Cloud 1*/}
        <Timeline
          wrapper={
            <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -100, width: '100%', height: '100%' }}/>
          }
          target={
            <Fragment>
              {/*<div style={{ position: 'fixed', backgroundRepeat: 'no', padding: 0, margin: 0, top: 0, left: '-100%', zIndex: -100, width: '100%', height: '100%',backgroundImage: `url(${data.cloud2.childImageSharp.fixed.src})`, backgroundSize: 'cover' }}> </div>*/}
              <img style={{ maxWidth: 'none', zIndex: 600, top: 0, left: '-30vw', right: 0, bottom: 0, width: '20%',  position: 'absolute' }} alt="cloud4" src={`${data.cloud4.childImageSharp.fixed.src}`}  />
            </Fragment>
          }
          repeat={-1}
        >
          <Tween to={{ x: '150vw', scale: 1, rotation: 0}} ease={"Linear.easeNone"} duration={100} />
        </Timeline>

        {/*Cloud 2*/}
        <Timeline
          wrapper={
            <div style={{ position: 'fixed', padding: 0, margin: 0, top: '7%', left: 0, zIndex: -100, width: '100%', height: '100%' }}/>
          }
          target={
            <Fragment>
              {/*<div style={{ position: 'fixed', backgroundRepeat: 'no', padding: 0, margin: 0, top: 0, left: '-100%', zIndex: -100, width: '100%', height: '100%',backgroundImage: `url(${data.cloud2.childImageSharp.fixed.src})`, backgroundSize: 'cover' }}> </div>*/}
              <img style={{ maxWidth: 'none', zIndex: -100, top: 0, left: '-30vw', right: 0, bottom: 0, width: '20%',  position: 'absolute' }} alt="cloud2"   src={`${data.cloud2.childImageSharp.fixed.src}`}  />
            </Fragment>
          }
          repeat={-1}
        >
          <Tween to={{ x: '150vw', scale: 1, rotation: 0}} ease={"Linear.easeNone"} duration={270} />
        </Timeline>
        {/*Cloud 3*/}
        <Timeline
          wrapper={
            <div style={{ position: 'fixed', padding: 0, margin: 0, top: '15%', left: 0, zIndex: -100, width: '100%', height: '100%' }}/>
          }
          target={
            <Fragment>
              {/*<div style={{ position: 'fixed', backgroundRepeat: 'no', padding: 0, margin: 0, top: 0, left: '-100%', zIndex: -100, width: '100%', height: '100%',backgroundImage: `url(${data.cloud2.childImageSharp.fixed.src})`, backgroundSize: 'cover' }}> </div>*/}
              <img style={{ maxWidth: 'none', zIndex: 500, top: 0, left: '-30vw', right: 0, bottom: 0, width: '20%',  position: 'absolute' }} alt="cloud7" src={`${data.cloud7.childImageSharp.fixed.src}`}  />
            </Fragment>
          }
          repeat={-1}
        >
          <Tween to={{ x: '150vw', scale: 1, rotation: 0}} ease={"Linear.easeNone"} duration={470} />
        </Timeline>
      </div>
  } else {
    bg = <div>
      {/* Background 1*/}
      <Timeline
        wrapper={
          <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -500, width: '100%', height: '100%' }}/>
        }
        target={
          <Fragment>
            <Img
              style={{ position: 'absolute', padding: 0, margin: 0, top: 0, left: 0, zIndex: -300, width: '100%', height: '100%', backgroundSize: 'cover' }}
              fixed={data.bg1Night.childImageSharp.fixed}
            />
          </Fragment>
        }
      />

      {/* Background 2*/}
      <Timeline
        wrapper={
          <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -500, width: '100%', height: '100%' }}/>
        }
        target={
          <Fragment>
            <Img
              style={{ position: 'absolute', padding: 0, margin: 0, top: 0, left: 0, zIndex: -300, width: '100%', height: '100%' }}
              fixed={data.bg2Night.childImageSharp.fixed}
            />
          </Fragment>
        }
      />

      {/* Background 3*/}
      <Timeline
        wrapper={
          <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -500, width: '100%', height: '100%' }}/>
        }
        target={
          <Fragment>
            <Img
              style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: '0%', zIndex: -200, width: '100%', height: '100%', backgroundSize: 'cover' }}
              fixed={data.bg3Night.childImageSharp.fixed}
            />
          </Fragment>
        }
        repeat={-1}
      >
      </Timeline>

      {/*Clouds*/}
      {/*Cloud 1*/}
      <Timeline
        wrapper={
          <div style={{ position: 'fixed', padding: 0, margin: 0, top: 0, left: 0, zIndex: -100, width: '100%', height: '100%' }}/>
        }
        target={
          <Fragment>
            {/*<div style={{ position: 'fixed', backgroundRepeat: 'no', padding: 0, margin: 0, top: 0, left: '-100%', zIndex: -100, width: '100%', height: '100%',backgroundImage: `url(${data.cloud2.childImageSharp.fixed.src})`, backgroundSize: 'cover' }}> </div>*/}
            <img style={{ maxWidth: 'none', zIndex: 600, top: 0, left: '-30vw', right: 0, bottom: 0, width: '20%',  position: 'absolute' }} alt="cloud 4 night" src={`${data.cloud4Night.childImageSharp.fixed.src}`}  />
          </Fragment>
        }
        repeat={-1}
      >
        <Tween to={{ x: '150vw', scale: 1, rotation: 0}} ease={"Linear.easeNone"} duration={100} />
      </Timeline>

      {/*Cloud 2*/}
      <Timeline
        wrapper={
          <div style={{ position: 'fixed', padding: 0, margin: 0, top: '7%', left: 0, zIndex: -100, width: '100%', height: '100%' }}/>
        }
        target={
          <Fragment>
            {/*<div style={{ position: 'fixed', backgroundRepeat: 'no', padding: 0, margin: 0, top: 0, left: '-100%', zIndex: -100, width: '100%', height: '100%',backgroundImage: `url(${data.cloud2.childImageSharp.fixed.src})`, backgroundSize: 'cover' }}> </div>*/}
            <img style={{ maxWidth: 'none', zIndex: -100, top: 0, left: '-30vw', right: 0, bottom: 0, width: '20%',  position: 'absolute' }} alt="cloud 2 night" src={`${data.cloud2Night.childImageSharp.fixed.src}`}  />
          </Fragment>
        }
        repeat={-1}
      >
        <Tween to={{ x: '150vw', scale: 1, rotation: 0}} ease={"Linear.easeNone"} duration={170} />
      </Timeline>
      {/*Cloud 3*/}
      <Timeline
        wrapper={
          <div style={{ position: 'fixed', padding: 0, margin: 0, top: '15%', left: 0, zIndex: -100, width: '100%', height: '100%' }}/>
        }
        target={
          <Fragment>
            {/*<div style={{ position: 'fixed', backgroundRepeat: 'no', padding: 0, margin: 0, top: 0, left: '-100%', zIndex: -100, width: '100%', height: '100%',backgroundImage: `url(${data.cloud2.childImageSharp.fixed.src})`, backgroundSize: 'cover' }}> </div>*/}
            <img style={{ maxWidth: 'none', zIndex: 500, top: 0, left: '-30vw', right: 0, bottom: 0, width: '20%',  position: 'absolute' }}  alt="cloud 7 night " src={`${data.cloud7Night.childImageSharp.fixed.src}`}  />
          </Fragment>
        }
        repeat={-1}
      >
        <Tween to={{ x: '150vw', scale: 1, rotation: 0}} ease={"Linear.easeNone"} duration={470} />
      </Timeline>
    </div>

  }
  return (
    <div style={{ zIndex: -100 }}>
      {bg}
    </div>
  )
};

export default TimelineComponent