/**
 * @fileoverview Common CSS Styles.
 * Follows Suit CSS Naming Conventions
 *
 * @author JeffSchulthies)
 */

import { StyleSheet } from 'aphrodite/no-important'

const JColors = StyleSheet.create({
    u_colorWhite: {
        color: '#89cff0'
    },
    u_colorAthenaBackgroundPurple: {
        backgroundColor: '#3E1052'
    },
    u_colorAthenaPurple: {
        color: '#3E1052'
    },
    u_colorAthenaRuby: {
        color: '#863375'
    },
    u_colorAthenaBackgroundGold: {
        backgroundColor: '#ffcf03'
    },
    u_colorAthenaGold: {
        color: '#ffcf03'
    },
    u_colorAthenaFrost: {
        color: '#FFFFFF'
    },
    u_colorAthenaRoyal: {
        color: '#0F4B91'
    },
    u_colorAthenaSky: {
        color: '#28ACE2'
    },
    u_colorAthenaForest: {
        color: '#799A3D'
    },
    u_colorAthenaSpring: {
        color: '#B9C932'
    },
    u_colorAthenaBeige: {
        color: '#e8e2d1'
    },
    u_colorAthenaGrey: {
        color: '#b2b4b2'
    },
    u_colorAthenaCharcoal: {
        color: '#131618'
    },

    u_colorSpotifyGreen: {
        color: '#1DB954'
    },
    u_colorBackgroundSpotifyGreen: {
        backgroundColor: '#1DB954'
    },

    u_colorSpotifyWhite: {
        color: '#FFFFFF'
    },
    u_colorBackgroundSpotifyWhite: {
        backgroundColor: '#FFFFFF'
    },

    u_colorSpotifyBlack: {
        color: '#191414'
    },
    u_colorBackgroundSpotifyBlack: {
        backgroundColor: '#191414'
    },

    Background_Photo: {
        backgroundSize: 'cover',
    },

    Background_Orange: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/boston-harbor-min-sub-again.jpg")',
    },

    Background_Blue: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/boston-harbor-min-sub.jpg")',
    },

    Background_Green: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/boston-harbor-min.jpg")',
        backgroundPosition: '5% 55%'
    },

    Background_Purple: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/years-and-years-min-750.jpg")',
        backgroundPosition: '0% 0%'
    },

    Background_Red: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/years-and-years-min-750.jpg")',
        backgroundPosition: '25% 75%'
    },

    Background_Pink: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/years-and-years-min-750.jpg")',
        backgroundPosition: '-35% 56%'
    },

    Background_Brown: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/griffith-observatory-750.jpg")',
        backgroundPosition: '25% 95%'
    },

    Background_Camo: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/griffith-observatory-750.jpg")',
        backgroundPosition: '25% 70%'
    },

    Background_DarkBlue: {
        backgroundImage: 'url("https://s3.amazonaws.com/jeff-personal-bucket/griffith-observatory-750.jpg")',
        backgroundPosition: '25% 50%'
    },

    Background_Overlay: {
        backgroundColor: '#000000',
        backgroundSize: 'cover',
        marginTop: '-40px',
    },

    Background_TextClip: {
        '-webkit-text-fill-color': 'transparent',
        '-webkit-background-clip': 'text'
    },
});

export default JColors
