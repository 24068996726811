import React from 'react';
import { css } from 'aphrodite';

import style from './style';
import TypographyStyle from "../styles/skeleton/TypographyStyle"
import GridStyle from "../styles/skeleton/GridStyle"
import UtilityStyle from "../styles/skeleton/UtilityStyle"

const Footer = () => { 
  return (
    <footer
			className={css(style.Footer)}>
			<div className={css(GridStyle.Container, style.Footer_Text)}>
				<div>
					<div className={css(TypographyStyle.Base, GridStyle.Columns, GridStyle.SixColumns)}>© {new Date().getFullYear()} Jeff Schulthies</div>
					<div className={css(TypographyStyle.Base, UtilityStyle.u_justifyRight, GridStyle.Columns, GridStyle.SixColumns)}>Artisanally crafted webpage</div>
				</div>
			</div>
    </footer>
  )
}

export default Footer;
