import React from 'react';
import { css } from 'aphrodite/no-important';
import navbarStyle from "./navbar-style"
import { Link } from "gatsby"

// TODO Change these to props. Makes it reusable
const NavBar = () => {
  return (
    <header className={css(navbarStyle.Header, navbarStyle.u_colorWhite)}>
      <nav className={css(navbarStyle.NavBar)}>
        <Link activeClassName={css(navbarStyle.NavBar_Link, navbarStyle.u_activeDecoration)} className={css(navbarStyle.NavBar_Link)} to="/">home</Link>
        <Link activeClassName={css(navbarStyle.NavBar_Link, navbarStyle.u_activeDecoration)} className={css(navbarStyle.NavBar_Link)} to="/about">about</Link>
        <a className={css(navbarStyle.NavBar_Link)} href="http://by.jeffs.io">blog</a>
      </nav>
    </header>
  )
}

export default NavBar;
