import JColors from "./components/styles/JColors"

export const hours = new Date().getHours()
export const isDayTime = hours > 6 && hours <= 17

export function chooseBackground() {
  let backgrounds = [
      JColors.Background_Orange,
      JColors.Background_Blue,
      JColors.Background_Green,
      JColors.Background_Purple,
      JColors.Background_Red,
      JColors.Background_Pink,
      JColors.Background_Brown, 
      JColors.Background_Camo, 
      JColors.Background_DarkBlue,
    ]

  return backgrounds[Math.floor(Math.random() * backgrounds.length)];
}
