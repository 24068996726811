import { StyleSheet } from 'aphrodite/no-important'

const style = StyleSheet.create({
	Footer: {
		backgroundColor: "#000000",
		opacity: 0.8,
		position: 'relative',
		bottom: '0',
		height: '40px',
		width: '100%',
		marginTop: '0px',
		overflowY: 'hidden',
		color: '#FFFFFF',
	},

	Footer_Text: {
		lineHeight: '40px',

        /* Small Mobile Size */
        '@media (min-width: 0px)': {
			fontSize: '.8em'
        },

        /* Larger than mobile */
        '@media (min-width: 400px)': {
			fontSize: '.8em'
        },

        /* Larger than phablet (also point when grid becomes active) */
        '@media (min-width: 550px)': {
			fontSize: '.8em'
        },

        /* Larger than tablet */
        '@media (min-width: 750px)': {
			fontSize: '.8em'
        },

        /* Larger than desktop */
        '@media (min-width: 1000px)': {
			fontSize: '.8em'
        },

        /* Larger than Desktop HD */
        '@media (min-width: 1200px)': {
			fontSize: '.8em'

        },


	},

    NavBar: {
		position: 'absolute',
	    right: '0',
	    bottom: '0',
	},
    NavBar_Link: {
	    fontFamily: 'Not-Roman',
	    fontWeight: 'bold',
	    fontSize: '1.5rem',
	    opacity: '1',
	    float: 'left',
	    textDecoration: 'none',
	    color: 'inherit',
	    paddingLeft: '10px',
	    paddingRight: '10px',
		transition: '.2s',
		
		':hover': {
			color: '#F7E154',
			transition: '.2s'
		},

		/* Small Mobile Size */
	    '@media (min-width: 0px)': {
		    fontSize: '1.1rem',
		    paddingLeft: '4px',
		    paddingRight: '4px',
	    },

		/* Larger than mobile */
	    '@media (min-width: 400px)': {
		    fontSize: '1.2rem',
		    paddingLeft: '5px',
		    paddingRight: '5px',
	    },

		/* Larger than phablet (also point when grid becomes active) */
	    '@media (min-width: 550px)': {
		    fontSize: '1.3rem',
    	    paddingLeft: '7px',
		    paddingRight: '7px',
	    },

		/* Larger than tablet */
	    '@media (min-width: 750px)': {
		    fontSize: '1.5rem',
    	    paddingLeft: '8px',
		    paddingRight: '8px',
	    },

		/* Larger than desktop */
	    '@media (min-width: 1000)': {
		    fontSize: '1.5rem',
    	    paddingLeft: '9px',
		    paddingRight: '9px',
	    },

		/* Larger than Desktop HD */
	    '@media (min-width: 1200)': {
		    fontSize: '2.0rem',
    	    paddingLeft: '10px',
		    paddingRight: '10px',
	    },

    },
});

export default style
