import { StyleSheet } from 'aphrodite/no-important'

const navbarStyle = StyleSheet.create({
  Header: {
    top: '0',
    width: '100%',
    height: '40px',
    position: 'fixed',
    zIndex: '1000',
  },

  NavBar: {
    position: 'relative',
    float: 'right',
    paddingRight: '10px',
    transition: '.2s',
    zIndex: '1000',
    backgroundColor: '#AAAAAA',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '0px',
    fontFamily: 'Not-Roman',
    fontWeight: '200',
    fontSize: '5vh',

    /* Small Mobile Size */
    '@media (min-width: 0px)': {
      paddingRight: '4px',
    },

    /* Larger than mobile */
    '@media (min-width: 400px)': {

    },

    /* Larger than phablet (also point when grid becomes active) */
    '@media (min-width: 550px)': {

    },

    /* Larger than tablet */
    '@media (min-width: 750px)': {

    },

    /* Larger than desktop */
    '@media (min-width: 1000)': {

    },

    /* Larger than Desktop HD */
    '@media (min-width: 1200)': {

    },
  },

  NavBar_Link: {
    fontFamily: 'Not-Roman',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    opacity: '1',
    float: 'left',
    textDecoration: 'none',
    paddingLeft: '10px',
    paddingRight: '10px',
    transition: '.2s',
    color: '#FFFFFF',

    ':hover': {
      color: '#F7E154',
      transition: '.2s'
    },

    /* Small Mobile Size */
    '@media (min-width: 0px)': {
      fontSize: '1.1rem',
      paddingLeft: '4px',
      paddingRight: '4px',
    },

    /* Larger than mobile */
    '@media (min-width: 400px)': {
      fontSize: '1.2rem',
      paddingLeft: '5px',
      paddingRight: '5px',
    },

    /* Larger than phablet (also point when grid becomes active) */
    '@media (min-width: 550px)': {
      fontSize: '1.3rem',
      paddingLeft: '7px',
      paddingRight: '7px',
    },

    /* Larger than tablet */
    '@media (min-width: 750px)': {
      fontSize: '1.5rem',
      paddingLeft: '8px',
      paddingRight: '8px',
    },

    /* Larger than desktop */
    '@media (min-width: 1000)': {
      fontSize: '1.5rem',
      paddingLeft: '9px',
      paddingRight: '9px',
    },

    /* Larger than Desktop HD */
    '@media (min-width: 1200)': {
      fontSize: '2.0rem',
      paddingLeft: '10px',
      paddingRight: '10px',
    },

  },

  u_colorWhite: {
    color: '#FFFFFF'
  },

  u_activeDecoration: {
    color: '#F7E154',
    textDecoration: 'underline'
  },

  // Insert heights
  // u_sm_navBar: {

  // },

  // u_md_navBar: {

  // },

  // u_lg_navBar: {

  // },

});

export default navbarStyle